import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import { DEFAULT_PAGE_SIZE } from "./../../../../constants";
import {
  ManageUserAPIServices,
  UserProfileAPIServices,
  WebServiceResponse,
} from "utils/services/AppUrls";
import {
  performGetRequest,
  performPostRequest,
} from "utils/services/NetworkHandler";
import { CompanyRoleResult } from "../interfaces/interfaces";
import { Company } from "@ivueit/vue-engine";

// Fetches the list of users available in the current company
export const getAllUsersFromMyCompany = async (
  pageNumber: number
): Promise<WebServiceResponse> => {
  const url = `${ManageUserAPIServices.getAllUsersOnMyCompany}?pageSize=${5000}&pageNumber=${pageNumber}`;
  return await performGetRequest(url);
};

// Fetches the list of users available in the current company
export const getOrgUserByEmail = async (
  email: string
): Promise<WebServiceResponse> => {
  const url = `${
    ManageUserAPIServices.getOrgUserByEmail
  }?email=${email.trim()}`;
  return await performGetRequest(url);
};

// Activates/Deactivates a user with their email ID
export const activateDeactivateUserWithEmail = async (
  email: string,
  isActive: boolean
): Promise<WebServiceResponse> => {
  return await performPostRequest(ManageUserAPIServices.setUserActiveInactive, {
    email: email.trim(),
    isActive: isActive,
  });
};

// Fetches the roles for each company for the given id
export const getCompanyRolesByEmail = async (
  email: string
): Promise<WebServiceResponse> => {
  const url = `${ManageUserAPIServices.getCompanyRolesByEmail}?email=${email}`;
  return await performGetRequest(url);
};

// Fetches the details of company by their id
export const getCompanyDetailsById = async (
  companyId: string
): Promise<WebServiceResponse> => {
  const url = `${ManageUserAPIServices.getCompanyById}/${companyId}`;
  return await performGetRequest(url);
};

// Updates the details of company by their id
export const updateCompanyDetails = async (
  data: Company
): Promise<WebServiceResponse> => {
  const url = `${ManageUserAPIServices.updateCompanyById}`;
  return await performPostRequest(url, data);
};

// Updates a user data based on their ID (User edit)
export const updateOrgUserById = async (
  userData: UserInfo
): Promise<WebServiceResponse> => {
  return await performPostRequest(UserProfileAPIServices.updateOrgUser, {
    designation: {
      value: userData.designation,
    },
    firstName: {
      value: userData.firstName,
    },
    lastName: {
      value: userData.lastName,
    },
    orgUserId: userData.id,
    profileImgId: {
      value: userData.profileImgId,
    },
    marketingEnabled: {
      value: userData.marketingEnabled,
    },
  });
};

// Creates a new user
export const createClientOrgUser = async (
  companyId: string,
  companyGroupId: string,
  userInfo: UserInfo
): Promise<WebServiceResponse> => {
  return await performPostRequest(ManageUserAPIServices.createClientOrgUser, {
    companyId: companyId,
    companyGroupId: companyGroupId,
    designation: userInfo.designation,
    email: userInfo.email,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
  });
};

// Creates a new user
export const whiteListEmailAndUpdateRoles = async (
  newRoles: CompanyRoleResult
): Promise<WebServiceResponse> => {
  return await performPostRequest(
    ManageUserAPIServices.whiteListEmailAndOverwriteCompanyRoles,
    newRoles
  );
};

// Send invite email
export const sendNewUserEmailInvite = async (
  email: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(
    ManageUserAPIServices.sendInviteEmailForNewUser,
    {
      email: email,
    }
  );
};

// Rollbacks the user creation
export const rollbackOrgUserCreation = async (
  userId: string,
  companyId: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(ManageUserAPIServices.removeOrgUser, {
    orgUserId: userId,
    companyId: companyId,
  });
};

// Rollbacks email white listing and role assignment process
export const rollbackEmailWhiteListing = async (
  emailId: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(
    ManageUserAPIServices.removeWhiteListedEmailAndRoles,
    {
      email: emailId,
    }
  );
};
