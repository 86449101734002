import DashboardContentLayout from "layouts/DashboardContentLayout";
import { AppBar, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import Icon from "@mdi/react";
import { mdiImageAlbum, mdiViewGrid } from "@mdi/js";
import breakpoints from "assets/theme/base/breakpoints";
import MediaHubGallery from "./components/MediaHubGallery";
import MediaHubAlbums from "./components/MediaHubAlbums";
import MDBox from "components/MDBox";

enum TabOrientation {
  horizontal = "horizontal",
  vertical = "vertical",
}
const MediaHubHome = () => {
  const [tabOrientation, setTabOrientation] = useState<TabOrientation>(
    TabOrientation.horizontal
  );
  const [selectedTab, setSelectedTab] = useState(0);

  // Handle
  const handleSetTabValue = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  // Handles the tab orientation based on the screen size
  // For smaller screen the tabs wil be listed vertically
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabOrientation(TabOrientation.vertical)
        : setTabOrientation(TabOrientation.horizontal);
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabOrientation]);

  return (
    <DashboardContentLayout needCardBackground={false}>
      <MDBox ml={1.8} mr={1.6}>
        {/* Tabs */}
        <MDBox display="flex" mt={2}>
          <MDBox>
            <AppBar position="static">
              <Tabs
                orientation={tabOrientation}
                value={selectedTab}
                onChange={handleSetTabValue}
              >
                <Tab
                  label="Gallery"
                  icon={<Icon path={mdiViewGrid} size={0.8} />}
                  sx={{ px: 2, fontSize: selectedTab === 0 ? 14 : 16 }}
                />
                <Tab
                  label="Albums"
                  icon={<Icon path={mdiImageAlbum} size={0.8} />}
                  sx={{ px: 2, fontSize: selectedTab === 1 ? 14 : 16 }}
                />
              </Tabs>
            </AppBar>
          </MDBox>
        </MDBox>
        {/* Tab contents */}
        <MDBox display="flex" flexDirection="column" minHeight="0" height="calc(100vh - 160px)">
          {selectedTab === 0 ? (
            <MediaHubGallery isGalleryScreen />
          ) : (
            <MediaHubAlbums />
          )}
        </MDBox>
      </MDBox>
    </DashboardContentLayout>
  );
};

export default MediaHubHome;
