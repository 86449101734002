import { Dialog, DialogContent } from "@mui/material";
import MDBox from "components/MDBox";
import { mdiArrowExpand } from "@mdi/js";
import { useEffect, useState } from "react";
import { MapFullViewDialogContent } from "./MapFullViewDialogContent";
import { CustomMapMarker } from "./googlemap/CustomMapMarker";
import { useVueProvider } from "pages/dashboard/home/vues/context/VueProvider";
import { IndividualVue } from "../utils/vue_detail_interface";
import { LatLng } from "@ivueit/vue-engine";
import { CustomMapComponent } from "./googlemap/CustomMapComponent";
import { OverlayViewF, OverlayView } from "@react-google-maps/api";

interface Props {
  vue: IndividualVue;
  isReadOnly: boolean;
}

export const VueMapSection = (props: Props) => {
  const { vue, photos, videos } = props.vue;
  const { latitude, longitude, canonicalId } = vue;
  const [openMapFullViewDialog, setOpenMapFullViewDialog] =
    useState<boolean>(false);
  const { storeLatestSelectedVueImage, latestSelectedVueImage } =
    useVueProvider();
  const [hoveredMarkerId, setHoveredMarkerId] = useState<string>("");
  const [coords, setCoords] = useState<LatLng | null>({
    latitude: latitude,
    longitude: longitude,
  });

  useEffect(() => {
    if (!latestSelectedVueImage) {
      setCoords({
        latitude: latitude,
        longitude: longitude,
      });
    } else {
      /// Checking whether there is any previously selected / locally stored photo
      /// If so, then checks whether the vue is same & then setting the marker ID, coords
      /// So that, map will render with that coords in the center
      const key = Object.keys(latestSelectedVueImage)[0];
      const locallyStoredImage = latestSelectedVueImage[key];
      if (locallyStoredImage.id !== hoveredMarkerId) {
        if (locallyStoredImage && key === canonicalId) {
          setHoveredMarkerId(locallyStoredImage.id);
          setCoords({
            latitude: locallyStoredImage.location.latitude,
            longitude: locallyStoredImage.location.longitude,
          });
        } else {
          setCoords({
            latitude: latitude,
            longitude: longitude,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vue, latestSelectedVueImage]);

  /// Gets the dialog with map full view
  const getMapFullViewDialog = () => {
    return (
      <Dialog open={true} onClose={null} maxWidth={false} fullWidth>
        <DialogContent sx={{ padding: "0" }}>
          <MapFullViewDialogContent
            toggleButtonClick={() => {
              setOpenMapFullViewDialog(false);
            }}
            vueDetail={props.vue}
            isReadOnly={props.isReadOnly}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const getListOfMarkers = () => {
    /// Sorted the medias - Escalated ones to be at the top & remaining ones at the bottom
    const medias = [...photos, ...videos];
    return [
      <OverlayViewF
        key={"vueLocation"}
        position={{
          lat: latitude,
          lng: longitude,
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <CustomMapMarker
          lat={latitude}
          lng={longitude}
          zIndex={4}
          onHover={() => {}}
          color="#57FF6F"
        />
      </OverlayViewF>,
      ...medias
        .map((media, index) => {
          const { location, escalated, id, serialNumber } = media;
          const { latitude, longitude } = location;
          const hasDefinedLocation =
            location && location.latitude && location.longitude;
          const selectedMarker = hoveredMarkerId === id;
          const markerColor = escalated
            ? "#AE1709"
            : selectedMarker
            ? "#4CAF50"
            : "white";
          const textColor = escalated ? "#FFFFFF" : "#000000";
          const zIndex = selectedMarker ? 1000 : escalated ? 999 : 4;
          return hasDefinedLocation ? (
            <OverlayViewF
              key={id}
              position={{
                lat: latitude,
                lng: longitude,
              }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <CustomMapMarker
                lat={latitude}
                lng={longitude}
                zIndex={zIndex}
                color={markerColor}
                textColor={textColor}
                content={serialNumber}
                onHover={() => {
                  storeLatestSelectedVueImage(canonicalId, media);
                  setHoveredMarkerId(id);
                }}
              />
            </OverlayViewF>
          ) : (
            <></>
          );
        }),
    ];
  };

  return (
    <>
      {openMapFullViewDialog && getMapFullViewDialog()}
      <MDBox
        height="500px"
        sx={{
          border: "1px solid #344767",
          borderRadius: "5px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <CustomMapComponent
          onlyUpdateViewPortOnDrag
          onClickToggleButton={() => {
            setOpenMapFullViewDialog(true);
          }}
          fullViewIconPath={mdiArrowExpand}
          handleMapDrag={() => {}}
          coordinates={coords}
          markerComponents={getListOfMarkers()}
        />
      </MDBox>
    </>
  );
};
