import { mdiDotsVertical } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Card,
  CardMedia,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { AlbumInfo } from "../utils/interfaces";
import { WebServiceStatus, loaderStyle } from "@ivueit/vue-engine";
import { generatePresignedPhotoFileURL } from "../../vues/services/VueServices";
import placeholderImage from "assets/images/broken_image.png";

interface Props {
  content: AlbumInfo;
  onClick: () => void;
  onRenameButtonClick: () => void;
  onCreatePdfButtonClick: () => void;
  onDeleteButtonClick: () => void;
}

const AlbumItem = ({
  content,
  onClick,
  onRenameButtonClick,
  onCreatePdfButtonClick,
  onDeleteButtonClick,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [thumbnailImage, setThumbnailImage] = useState<string>("");
  const [showLoader, setShowLoader] = useState(false);
  const fileID = content.thumbnail_file_id ?? "";
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRenameClick = () => {
    onRenameButtonClick();
    handleClose();
  };

  const handleCreatePdfClick = () => {
    onCreatePdfButtonClick();
    handleClose();
  };

  const handleDeleteClick = () => {
    onDeleteButtonClick();
    handleClose();
  };

  useEffect(() => {
    let imageURL;
    const getThumbnailImage = async () => {
      setShowLoader(true);
      if (fileID) {
        const response = await generatePresignedPhotoFileURL(fileID);
        if (response.status === WebServiceStatus.success) {
          const { url } = response.data;
          imageURL = url;
        } else {
          imageURL = placeholderImage;
        }
      } else {
        imageURL = placeholderImage;
      }
      setThumbnailImage(imageURL);
      setShowLoader(false);
    };
    getThumbnailImage();
  }, [fileID]);

  return (
    <Card
      onClick={onClick}
      sx={{
        position: "relative",
        mb: "40",
        "&:hover .card-header": {
          transform: "translate3d(0, -15px, 0)",
        },
        cursor: "pointer",
      }}
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        display="flex"
        justifyContent="center"
        zIndex={2}
        bgcolor="#fff !important"
        sx={{
          borderRadius: "0.75rem",
          boxShadow: "0 5px 12px 0 rgba(0, 0, 0, 0.10)",
          overflow: "hidden",
        }}
      >
        {showLoader ? (
          <MDBox height="186px" position="relative">
            <CircularProgress color="success" sx={loaderStyle} />
          </MDBox>
        ) : (
          <CardMedia
            component="img"
            height="186px"
            width="auto"
            image={thumbnailImage}
            onError={() => {
              setThumbnailImage(placeholderImage);
            }}
            alt="Vue"
            sx={{
              objectFit:
                thumbnailImage.toLowerCase() === placeholderImage.toLowerCase()
                  ? "cover"
                  : "contain",
              margin: 0,
              width: "100%",
              cursor: "pointer",
              borderRadius: "0",
            }}
          />
        )}
      </MDBox>
      <MDBox
        textAlign="center"
        p={2.8}
        position="relative"
      >
        <MDBox
          position="absolute"
          top="20px"
          right="14px"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <IconButton onClick={handleClick}>
            <Icon path={mdiDotsVertical} size={0.8} />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleRenameClick}>Rename</MenuItem>
            <MenuItem
              disabled={content.count === 0}
              onClick={handleCreatePdfClick}
            >
              Create PDF
            </MenuItem>
            <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
          </Menu>
        </MDBox>
        <MDTypography variant="h5" title={content.name} sx={{overflow:"hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}} mx={1.8}>{content.name}</MDTypography>
        <MDTypography variant="h6" sx={{ fontWeight: "400", mt: "5px" }}>
          {content.count.toString()} {content.count > 1 ? "Items" : "Item"}
        </MDTypography>
        <MDTypography variant="h6" sx={{ fontWeight: "400", mt: "5px" }}>
          Created on:{" "}
          {content.createdAt.formatUTCNanoSecondsToString("MM/DD/YYYY", false)}
        </MDTypography>
      </MDBox>
    </Card>
  );
};

export default AlbumItem;
